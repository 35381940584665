.homeContainer {  
  background: url(/src/images/lep1_compressed.jpg) no-repeat center top fixed;  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;  

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}